



















import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

@Component({
  components: {
    VSelect,
  },
})
export default class TableSelectField extends Vue {
  /**
   * Props
   */
  @Prop() private value!: any;
  @Prop() private options!: any;
  @Prop({ default: false, type: Boolean }) private disabled!: boolean;
  @Prop({ default: false, type: Boolean }) private small!: boolean;
  @Prop({ default: false, type: Boolean }) private dimmed!: boolean;

  /**
   * Getters
   */
  private get classNames(): object {
    return {
      'table-select-field--small': this.small,
      'table-select-field--dimmed': this.dimmed,
    };
  }

  /**
   * Methods
   */
  private selectedOptionLabel(value: number | string): string {
    const selected: any = this.options.find((option: any) => {
      return option.value.toString() === value.toString();
    });

    return selected ? selected.label : '';
  }

  /**
   * Emit event
   */
  @Emit()
  private input({ value }: { value: number | string }) {
    return value;
  }
}
